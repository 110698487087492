import { Endpoint } from '@/lib/api-builder';
import imp from '@/lib/imp';
import { UvRoute, createInjectionComponent } from '@/lib/router';

type QuantitiesApi = {
  create: Endpoint;
  edit: Endpoint;
  delete: Endpoint;
};

export default (baseRouteName: string, parentRoute: string, quantitiesApi: QuantitiesApi, source: string): UvRoute => ({
  path: 'quantities',
  props: (route) => ({
    ...route.params,
    baseRouteName,
    parentRoute,
    quantitiesApi,
  }),
  component: createInjectionComponent(['item', 'baseRouteName', 'parentRoute', 'quantitiesApi']),
  children: [
    {
      path: 'create',
      props: true,
      name: `${baseRouteName}.quantities.create`,
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/update/view/quantities/Create.vue' /* webpackChunkName: 'inventory.active.scriptic' */
          ),
      ),
    },
    {
      path: ':quantityId',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/update/view/quantities/View.vue' /* webpackChunkName: 'inventory.active.scriptic' */
          ),
      ),
      props: true,
      children: [
        {
          path: 'mf/edit',
          name: `${baseRouteName}.items.mf.quantities.edit`,
          props: (route: any) => ({
            ...route.params,
            disabled: ['qty'],
            baseRouteName: `${baseRouteName}.items.show`,
          }),
          component: imp(
            () =>
              import(
                `@/pages/inventory/active/import/${source}/lists/mf/quantities/Edit.vue` /* webpackChunkName: 'inventory.active.scriptic' */
              ),
          ),
        },
        {
          path: 'oi/edit',
          name: `${baseRouteName}.items.oi.quantities.edit`,
          props: (route: any) => ({
            ...route.params,
            disabled: ['qty'],
            baseRouteName: `${baseRouteName}.items.show`,
          }),
          component: imp(
            () =>
              import(
                `@/pages/inventory/active/import/${source}/lists/oi/quantities/Edit.vue` /* webpackChunkName: 'inventory.active.scriptic' */
              ),
          ),
        },
      ],
    },
  ],
});
