import { PrepTree, prepTree, Tree, TreeNode } from '@/lib/trees';
import * as trees from '@/lib/trees';
import { InjectionKey, reactive, UnwrapRef } from 'vue';
import { Endpoint, QsParamObj } from '@/lib/api-builder';
import { Location } from 'vue-router';

export interface NodesManagerState {
  tree: Tree | null;
  loading: boolean;
  error: Error | null;
  loadPromise: Promise<void> | null;
}

export interface NodesManager {
  state: NodesManagerState;
  loadTree: () => Promise<void>;
  addNode: (parent: TreeNode, options: { label: string }) => Promise<TreeNode>;
  addRootNode: (options: { label: string }) => Promise<TreeNode>;
  renameNode: (node: TreeNode, options: { label: string }) => Promise<TreeNode>;
  moveNode: (node: TreeNode, newParent: TreeNode) => Promise<TreeNode>;
  deleteNode: (node: TreeNode) => Promise<boolean>;
}

interface NodesManagerOptions {
  api: Record<string, Endpoint>;
  initialState?: UnwrapRef<NodesManagerState>;
  catchLoadError?: boolean;
  urlParams?: QsParamObj;
}

const makeState = () =>
  reactive<NodesManagerState>({
    tree: null,
    loading: false,
    error: null,
    loadPromise: null,
  });

export default (opts: NodesManagerOptions): NodesManager => {
  const { api, initialState, urlParams = {}, catchLoadError = true } = opts;

  const state = initialState || makeState();

  const setTree = (data: PrepTree) => {
    state.tree = prepTree(data);
  };

  const loadTree = () => {
    if (state.loadPromise) {
      return state.loadPromise;
    }

    state.loading = true;

    state.loadPromise = api.tree
      .req({ params: urlParams })
      .then((res) => res.json().then((body) => (res.ok ? setTree(body.data) : Promise.reject(new Error(body.error)))))
      .catch((e) => {
        state.error = e;

        return catchLoadError ? undefined : Promise.reject(e);
      })
      .finally(() => {
        state.loading = false;
      });

    return state.loadPromise;
  };

  const checkTree = async (): Promise<void> => {
    if (state.loadPromise && state.loading) {
      await state.loadPromise;
    }
    if (!state.tree) {
      throw new Error('Tree is not loaded');
    }
  };

  const addRootNode = async ({ label }: { label: string }) => {
    await checkTree();

    return api.add
      .req({
        params: urlParams,
        body: { label },
      })
      .then((response) =>
        response.json().then(async (payload) => {
          if (response.ok) {
            if (!state.tree) {
              throw new Error('Tree missing.');
            }
            return trees.createRootNode(state.tree, {
              ...payload.node,
              children: [],
            });
          }

          return Promise.reject(new Error(payload.error));
        }),
      );
  };

  const addNode: NodesManager['addNode'] = async (parent, { label }) => {
    await checkTree();

    return api.add
      .req({
        params: urlParams,
        body: {
          parentId: parent.id,
          label,
        },
      })
      .then((response) =>
        response.json().then((payload) => {
          if (response.ok) {
            return trees.createNode(parent, {
              ...payload.node,
              children: [],
            });
          } else {
            return Promise.reject(new Error(payload.error));
          }
        }),
      );
  };

  const renameNode: NodesManager['renameNode'] = async (node, { label }) => {
    await checkTree();

    return api.rename
      .req({
        params: { nodeId: node.id, ...urlParams },
        body: { label },
      })
      .then((response) =>
        response.json().then((payload) => {
          if (response.ok) {
            return trees.renameNode(node, label);
          } else {
            return Promise.reject(new Error(payload.error));
          }
        }),
      );
  };

  const moveNode: NodesManager['moveNode'] = async (node, newParent) => {
    await checkTree();

    if (newParent && node.tree !== newParent.tree) throw new Error('Nodes are not in the same tree.');
    if (newParent === node.parent) throw new Error('No op');
    if (node === newParent) throw new Error('Self suck');
    if (newParent && trees.isChildOf(newParent, [node])) throw new Error('Cannot move into self.');

    return api.move
      .req({
        params: { nodeId: node.id, ...urlParams },
        body: { parentId: newParent ? newParent.id : null },
      })
      .then((response) =>
        response.json().then((payload) => {
          if (response.ok) {
            return trees.moveNode(node, newParent);
          } else {
            return Promise.reject(new Error(payload.error));
          }
        }),
      );
  };

  const deleteNode: NodesManager['deleteNode'] = async (node) => {
    await checkTree();

    return api.delete.req({ params: { nodeId: node.id, ...urlParams } }).then((response) =>
      response.json().then((payload) => {
        if (response.ok) {
          return true;
        } else {
          return Promise.reject(new Error(payload.error));
        }
      }),
    );
  };

  return {
    state,
    loadTree,
    addNode,
    addRootNode,
    renameNode,
    moveNode,
    deleteNode,
  };
};

export interface NodeAction {
  label: string;
  icon: string;
  iconColor: string;
  route: (node: TreeNode) => Location;
}

export const NodesManagerKey: InjectionKey<NodesManager> = Symbol('NodesManager');
export const RootRouteKey: InjectionKey<string> = Symbol('NodesManagerRootRoute');
export const NodeActionsKey: InjectionKey<NodeAction[]> = Symbol('NodesManagerNodeActions');
